var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { align: "left" } },
    [
      _c("el-row", { attrs: { width: "100%" } }, [
        _c(
          "div",
          { staticStyle: { "padding-bottom": "20px" } },
          [
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.handleSave()
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.save")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "filter-item",
                attrs: { type: "primary" },
                on: { click: _vm.handleAdd },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("button.add")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("GeneralEditForm", {
            ref: "GeneralEditForm",
            attrs: {
              columns: _vm.editColumns,
              editForm: _vm.editForm,
              rules: _vm.rules,
              inline: false,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }