var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: { placeholder: _vm.$t("label.inputKeywordToFilter") },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _vm._v(" "),
      _c("el-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "GeneralTree",
        staticClass: "filter-tree",
        attrs: {
          "node-key": _vm.treeKey,
          data: _vm.treeData,
          props: _vm.defaultProps,
          "default-expand-all": _vm.expandAll,
          "expand-on-click-node": false,
          "filter-node-method": _vm.filterNode,
          "default-expanded-keys": _vm.valueArr,
        },
        on: { "node-click": _vm.handleNodeClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }