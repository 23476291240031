import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generalTree } from '@/api/general';
export default {
  name: 'Index',
  props: {
    treeUrl: {
      type: String,
      default: null
    },
    treeKey: {
      type: String,
      default: 'value'
    },
    treeLabel: {
      type: String,
      default: 'label'
    }
  },
  data: function data() {
    return {
      treeData: [],
      filterText: '',
      defaultProps: {
        children: 'children',
        label: this.treeLabel
      },
      expandAll: false,
      valueArr: [],
      loading: false
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.GeneralTree.filter(val);
    },
    treeData: function treeData(val) {
      var _this = this;
      if (val && val.length <= 10) {
        this.valueArr = [];
        this.treeData.forEach(function (m) {
          _this.valueArr.push(m.value);
          if (m.children && m.children.length <= 5) {
            m.children.forEach(function (n) {
              _this.valueArr.push(n.value);
            });
          }
        });
      }
    }
  },
  created: function created() {
    this.getTreeData();
  },
  methods: {
    getTreeData: function getTreeData() {
      var _this2 = this;
      // console.log('getTreeData')
      this.loading = true;
      return new Promise(function (resolve, reject) {
        generalTree(_this2.treeUrl).then(function (response) {
          _this2.treeData = response.data.data;
          if (response.data.code !== '0') {
            _this2.$notify({
              title: _this2.$t('notification.error.title'),
              message: response.data.message,
              type: 'error',
              duration: 3000
            });
          }
          _this2.loading = false;
          //console.log(response.data)
          resolve(response);
        }).catch(function (error) {
          console.log(error);
          _this2.loading = false;
          reject(error);
        });
      });
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data[this.treeLabel].indexOf(value) !== -1;
    },
    handleNodeClick: function handleNodeClick(obj, node, component) {
      //console.log(obj)
      this.$parent.handleNodeClick(obj[this.treeKey]);
      // console.log(node)
      // console.log(component)
    }
  }
};