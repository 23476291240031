import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import waves from '@/directive/waves'; // waves directive
import GeneralEditForm from '@/components/GeneralEditForm';
import { generalDetail, generalSave, generalDelete, generalValueLabelList } from '@/api/general';
export default {
  name: 'Index',
  components: {
    GeneralEditForm: GeneralEditForm
  },
  directives: {
    waves: waves
  },
  props: {
    tableName: {
      type: String,
      default: ''
    },
    url: {
      type: Object,
      default: null
    },
    rowKey: {
      type: String,
      default: 'c_id'
    },
    rules: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      id: '',
      tableColumns: [],
      editColumns: [],
      updateColumns: [],
      editForm: {},
      loading: false
    };
  },
  computed: {
    currentTable: function currentTable() {
      var _this = this;
      var tdds = this.$store.getters.tableDataDictionary.tables;
      // console.log('currentTable()')
      if (tdds) {
        return tdds.find(function (value) {
          return value.name === _this.tableName;
        }).tableColumns;
      } else {
        return null;
      }
    },
    codes: function codes() {
      return this.$store.getters.codeDataDictionary.codes;
    }
  },
  watch: {
    currentTable: function currentTable(value) {
      // console.log('watch currentTable()')
      this.setTableColumns();
      this.getDetailData();
    },
    codes: function codes(value) {
      this.setSelectOptions();
    }
  },
  created: function created() {
    this.setTableColumns();
    this.getDetailData();
  },
  methods: {
    setTableColumns: function setTableColumns() {
      if (this.currentTable) {
        this.tableColumns = this.currentTable.filter(function (n) {
          return n.visible || n.editable;
        });
        this.editColumns = this.tableColumns;
        this.updateColumns = this.editColumns.filter(function (n) {
          return n.editable;
        });
        // console.log(this.updateColumns)
        this.setSelectOptions();
      }
    },
    setSelectOptions: function setSelectOptions() {
      var _this2 = this;
      var _iterator = _createForOfIteratorHelper(this.tableColumns.filter(function (c) {
          return c.isOption !== null && c.editable !== null && c.isOption === true && c.editable === true;
        })),
        _step;
      try {
        var _loop = function _loop() {
          var item = _step.value;
          //  console.log(item.code)
          var codeDD = _this2.codes.filter(function (n) {
            return n.propId === item.propId;
          });
          if (codeDD && codeDD[0] && codeDD[0].data.length > 0) {
            item.options = codeDD[0].data;
          }
          if (item.optionsUrl && item.optionsUrl.indexOf('/') >= 0) {
            _this2.getOptionItemSelectOptions(item);
          }
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    getDetailData: function getDetailData() {
      var _this3 = this;
      // console.log('getDetailData')
      //console.log(this.url.query)
      if (this.id && this.id !== '') {
        // this.id = this.url.params.id
        this.loading = true;
        return new Promise(function (resolve, reject) {
          generalDetail(_this3.url.getUrl, _this3.id).then(function (response) {
            _this3.editForm = response.data.data;
            if (response.data.code !== '0') {
              _this3.$notify({
                title: _this3.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 3000
              });
            }
            _this3.loading = false;
            //console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this3.loading = false;
            reject(error);
          });
        });
      } else {
        this.editForm = {};
      }
    },
    initData: function initData(id) {
      var _this4 = this;
      this.id = id;
      this.$refs.GeneralEditForm.$refs.editForm.resetFields();
      if (id == null || id == '') {
        this.editForm = {};
      } else {
        this.getDetailData();
      }
      //选择项来源自本表，则刷新
      var _iterator2 = _createForOfIteratorHelper(this.tableColumns.filter(function (c) {
          return c.isOption !== null && c.editable !== null && c.isOption === true && c.editable === true;
        })),
        _step2;
      try {
        var _loop2 = function _loop2() {
          var item = _step2.value;
          //  console.log(item.code)
          var codeDD = _this4.codes.filter(function (n) {
            return n.propId === item.propId;
          });
          if (item.optionsUrl && item.optionsUrl.indexOf('/') >= 0 && item.optionTable === _this4.tableName) {
            // console.log(item.code)
            _this4.getOptionItemSelectOptions(item);
          }
        };
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          _loop2();
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    handleAdd: function handleAdd() {
      var _this5 = this;
      //console.log('general tree edit handleAdd')
      //this.$parent.handleAdd()
      if (isNull(this.id)) {
        this.$confirm(this.$t("messageBox.confirm.addNewWithoutSave"), this.$t("messageBox.confirm.title"), {
          confirmButtonText: this.$t("button.confirm"),
          cancelButtonText: this.$t("button.cancel"),
          type: 'warning'
        }).then(function () {
          _this5.initData('');
        });
      } else {
        this.initData('');
      }
    },
    handleSave: function handleSave() {
      var _this6 = this;
      //console.log(formName)
      // console.log(this.$refs.GeneralEditForm.$refs.editForm)
      this.$refs.GeneralEditForm.$refs.editForm.validate(function (valid) {
        if (valid) {
          _this6.$confirm(_this6.$t("messageBox.confirm.body"), _this6.$t("messageBox.confirm.title"), {
            confirmButtonText: _this6.$t("button.confirm"),
            cancelButtonText: _this6.$t("button.cancel"),
            type: 'warning'
          }).then(function () {
            _this6.loading = true;
            // console.log(this.editForm)
            var updateData = {};
            var _iterator3 = _createForOfIteratorHelper(_this6.updateColumns),
              _step3;
            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var col = _step3.value;
                var o = {};
                o[col.code] = _this6.editForm[col.code];
                Object.assign(updateData, o);
              }
              //console.log(updateData)
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
            var notifyTitle = _this6.$t("notification.success.title");
            var notifyBody = _this6.$t("notification.success.body");
            return new Promise(function (resolve, reject) {
              generalSave(_this6.url.getUrl, _this6.id, updateData).then(function (response) {
                // console.log(response)
                if (response.data.code === '0') {
                  _this6.$notify({
                    title: _this6.$t("notification.success.title"),
                    message: _this6.$t("notification.success.body"),
                    type: 'success',
                    duration: 3000
                  });
                } else {
                  _this6.$notify({
                    title: _this6.$t('notification.error.title'),
                    message: response.data.message,
                    type: 'error',
                    duration: 5000
                  });
                }
                _this6.initData('');
                _this6.$parent.handleDataChanged();
                _this6.loading = false;
                resolve(response);
              }).catch(function (error) {
                _this6.loading = false;
                reject(error);
              });
              _this6.loading = false;
            }).catch(function (error) {
              console.error(error);
              _this6.loading = false;
            });
          });
        } else {
          console.log('validate errors found!!');
          return false;
        }
      });
      //
      this.loading = false;
    },
    handleDelete: function handleDelete(formName) {
      var _this7 = this;
      this.$confirm(this.$t("messageBox.confirm.body"), this.$t("messageBox.confirm.title"), {
        confirmButtonText: this.$t("button.confirm"),
        cancelButtonText: this.$t("button.cancel"),
        type: 'warning'
      }).then(function () {
        _this7.loading = true;
        var id = _this7.id;
        var notifyTitle = _this7.$t("notification.success.title");
        var notifyBody = _this7.$t("notification.success.body");
        return new Promise(function (resolve, reject) {
          generalDelete(_this7.url.getUrl, id).then(function (response) {
            //console.log(response)
            if (response.data.code === '0') {
              _this7.initData('');
              _this7.$parent.handleDataChanged();
              _this7.$notify({
                title: _this7.$t("notification.success.title"),
                message: _this7.$t("notification.success.body"),
                type: 'success',
                duration: 3000
              });
            } else {
              _this7.$notify({
                title: _this7.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this7.loading = false;
            resolve(response);
          }).catch(function (error) {
            _this7.loading = false;
            reject(error);
          });
        });
      });
    },
    getOptionItemSelectOptions: function getOptionItemSelectOptions(item) {
      var _this8 = this;
      // console.log('getOptionItemSelectOptions')
      if (item.optionsUrl && item.optionsUrl.length > 0) {
        this.loading = true;
        return new Promise(function (resolve, reject) {
          generalValueLabelList(item.optionsUrl).then(function (response) {
            item.options = response.data.data;
            // console.log(item.options)
            if (response.data.code !== '0') {
              _this8.$notify({
                title: _this8.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 3000
              });
            }
            _this8.loading = false;
            //console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this8.loading = false;
            reject(error);
          });
        });
      }
    }
  }
};